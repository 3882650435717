<template>
  <div class="mt-2" style="position: relative;">
    <AddMember :openAddMember.sync="openAddMember" />
    <ManageMember v-if="!openAddMember" />
  </div>
</template>

<script>
import AddMember from "./add";
import ManageMember from "./manage";

export default {
  components: { AddMember, ManageMember },
  data() {
    return { openAddMember: false };
  }
};
</script>
